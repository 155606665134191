
import {
  helperMixin,
  infinitySrollJobApplicantByStatus,
  interviewResponse,
  jobApplicantStatus,
  rescheduleResponse
} from "@/mixins";
import { defineComponent } from "vue";
import { JobApplicantStatus } from "@/enums";
import ActionTableColumn from "@/components/Column/ActionTableColumn.vue";
import DropdownItem from "@/components/Dropdown/DropdownItem.vue";
import BaseBadge from "@/components/Base/BaseBadge.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import { JobApplicantPaginate } from "@/models/jobApplicant";
import jobApplicantHttp from "@/http/jobApplicant";
import AvatarImg from "@/components/AvatarImg.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import ProfileDetailModal from "@/components/JobApplicant/ProfileDetailModal.vue";

export default defineComponent({
  mixins: [
    helperMixin,
    jobApplicantStatus,
    infinitySrollJobApplicantByStatus([])
  ],
  components: {
    DropdownItem,
    BaseBadge,
    ConfirmModal,
    AvatarImg,
    BaseTableRow,
    BaseTableColumn,
    BaseTable,
    ProfileDetailModal
  },
  data() {
    return {
      showProfileDetailModal: false
    };
  },
  computed: {
    jobApplicantHttp
  },
  methods: {
    showProfileDetail(jobApplicant: JobApplicantPaginate) {
      this.selectedRow = jobApplicant;
      this.showProfileDetailModal = true;
    }
  }
});
