import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center gap-1.5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_table_column = _resolveComponent("base-table-column")!
  const _component_base_table_row = _resolveComponent("base-table-row")!
  const _component_avatar_img = _resolveComponent("avatar-img")!
  const _component_base_badge = _resolveComponent("base-badge")!
  const _component_base_table = _resolveComponent("base-table")!
  const _component_profile_detail_modal = _resolveComponent("profile-detail-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_table, {
      colspan: "8",
      loading: _ctx.loading,
      onLoadMore: _ctx.loadMore,
      "has-more": _ctx.hasMore,
      noDataAvailable: _ctx.noDataAvailable,
      noResultFound: _ctx.noResultFound,
      hover: ""
    }, {
      header: _withCtx(() => [
        _createVNode(_component_base_table_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_base_table_column, {
              type: "label",
              class: "pr-3"
            }),
            _createVNode(_component_base_table_column, {
              type: "label",
              class: "pl-0"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.user.fullname")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.user.email")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.job.position_id")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.company.name")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, {
              type: "label",
              "text-align": "center"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.job_applicant.status")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row) => {
          return (_openBlock(), _createBlock(_component_base_table_row, {
            key: row.id,
            onClick: _withModifiers(($event: any) => (_ctx.showProfileDetail(row)), ["stop"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_base_table_column, { class: "pr-3" }, {
                default: _withCtx(() => [
                  _createVNode(_component_avatar_img, {
                    src: row.user.avatar_link,
                    alt: `${row.user.email}_avatar`
                  }, null, 8, ["src", "alt"])
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, { class: "pl-0" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.user.fullname), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.user.email), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.job.position.name), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.job.company.name), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, { class: "align-middle" }, {
                default: _withCtx(() => [
                  _createVNode(_component_base_badge, {
                    color: _ctx.badgeColor(row.status)
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("i", {
                          class: _normalizeClass(["text-base", _ctx.badgeIconClass(row.status)])
                        }, null, 2),
                        _createTextVNode(" " + _toDisplayString(_ctx.makeFirstCapital(_ctx.trans(`layout.job_applicant.${row.status}`))), 1)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["color"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["onClick"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["loading", "onLoadMore", "has-more", "noDataAvailable", "noResultFound"]),
    _createVNode(_component_profile_detail_modal, {
      modelValue: _ctx.showProfileDetailModal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showProfileDetailModal) = $event)),
      jobApplicant: _ctx.selectedRow,
      onRescheduleAccepted: _ctx.reload,
      onRescheduleRefused: _ctx.reload
    }, null, 8, ["modelValue", "jobApplicant", "onRescheduleAccepted", "onRescheduleRefused"])
  ], 64))
}